import React, { useContext, useEffect, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonItem,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonLabel,
  IonRow,
  IonCol,
  IonGrid,
  IonCardTitle,
  IonSlides,
  IonSlide,
  IonList
} from '@ionic/react';
import { useParams } from 'react-router-dom';
import TwoButtonSegment from '../components/TwoButtonSegment';
import StationsContext from '../data/stations-context';
import "./StationDetail.css";

import { LineChart, Line, BarChart, XAxis, Bar, YAxis, ResponsiveContainer } from 'recharts';
const StationDetail: React.FC = () => {

  const stationsCtx = useContext(StationsContext);

  const [sliderView, setSliderView] = useState<'0' | '1'>('0');
  const [wind, setWind] = useState<any>([]);
  const [temps, setTemps] = useState<any>([]);
  const [snowPack, setSnowPack] = useState<any>([]);
  //const [relativeHumidity, setRelativeHumidity] = useState<any>([]);
  const [precip, setPrecip] = useState<any>([]);

  const selectedStationId = useParams<{ stationId: string }>().stationId;



  const selectedStation = stationsCtx.stations.find(
    station => station.stationId == selectedStationId
  );

  const last24HoursData = selectedStation?.data.slice(Math.max(0, 23));

  const slideOpts = {
    speed: 400
  };

  const [swiper, setSwiper] = useState<any>({});

  const init = async function(this: any) {
    setSwiper(await this.getSwiper());
  };

  const segmentChangeHandler = (selectedValue: '0' | '1') => {
    setSliderView(selectedValue);
    if (swiper.slideTo) {
      swiper.slideTo(selectedValue, 400);
    }

  };

  const slideChangeHandler = () => {

    console.log("slider change");
    setSliderView(swiper.activeIndex);

  }

  useEffect(() => {

    if (selectedStation ?.data.length > 0) {


      let stationWind: any = []
      let stationSnowPack: any = []
      let stationPrecip: any = []
      let stationTemps: any = []

      for (let key = 0; key < 12; key++) {

        if (selectedStation?.data[key].windSpeedAvg) {

          stationWind.push({
            name: selectedStation.name,
            wind: selectedStation.data[key].windSpeedAvg
          });
        }

        if (selectedStation?.data[key].snowHeight) {

          stationSnowPack.push({
            name: selectedStation.name,
            hs: selectedStation.data[key].snowHeight
          });
        }

        if (selectedStation?.data[key].airTempAvg) {

          stationTemps.push({
            name: selectedStation.name,
            airTempAvg: selectedStation.data[key].airTempAvg
          });
        }

        {
          if (selectedStation?.data[key].hourlyPrecip && +selectedStation ?.data[key].hourlyPrecip > 0) {

            stationPrecip.push({
              name: selectedStation.name,
              hourlyPrecip: selectedStation.data[key].hourlyPrecip
            });
          }
          else if (+selectedStation?.data[key].hourlyPrecip < 0) {

            stationPrecip.push({
              name: selectedStation?.name,
              hourlyPrecip: 0
            });
          } else {
            stationPrecip.push({
              name: selectedStation?.name,
              hourlyPrecip: 0
            });
          }
        }

      }//data loop
      setWind(stationWind);
      setSnowPack(stationSnowPack);
      setTemps(stationTemps);
      setPrecip(stationPrecip);
    }
  }, [selectedStation]);

  const renderLineChart = (
    <IonCard>
      <IonCardContent>
        <IonCardTitle className="ion-text-center">
          Wind
  </IonCardTitle>
<ResponsiveContainer width="95%" height={400}>
        <LineChart data={wind}
        >
          <YAxis  width={45}/>
          <Line type="monotone" dataKey="wind" stroke="#8884d8" />
        </LineChart>
        </ResponsiveContainer>
      </IonCardContent>
    </IonCard>
  );

  const renderLineChart2 = (

      <ResponsiveContainer width="95%" height={400}>
        <LineChart  data={snowPack}>
          <YAxis width={45} domain={['dataMin - 10', 'dataMax + 10']}/>
          <Line type="monotone" dataKey="hs" stroke="#8884d8" />
        </LineChart>
        </ResponsiveContainer>

  );

  const renderLineChart3 = (

<ResponsiveContainer width="95%" height={400}>
        <LineChart  data={temps}>
          <YAxis width={45}/>
          <XAxis />
          <Line type="monotone" dataKey="airTempAvg" stroke="#8884d8" />
        </LineChart>
        </ResponsiveContainer>

  );

  const renderBarChart = (

<ResponsiveContainer width="95%" height={400}>
        <BarChart

          data={precip}>
          <YAxis width={45}/>
          <Bar dataKey="hourlyPrecip" fill="#8884d8" />

        </BarChart>
        </ResponsiveContainer>

  );

  let content = <h2 className="ion-text-center">No station data</h2>;

  if (!selectedStation) {
    content = <h2 className="ion-text-center">No station data</h2>;
  }

  if (selectedStation && selectedStation.data.length > 0) {
    content = (

      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Elevation: {selectedStation.elevation}m</IonCardSubtitle>
          <IonCardSubtitle>Source: {selectedStation.source}</IonCardSubtitle>
          <IonCardSubtitle>Obs Time: {selectedStation.data[0].measurementDateTime}</IonCardSubtitle>


        </IonCardHeader>



      </IonCard>


    );
  } else {
    content =
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>No Station Data Available</IonCardSubtitle>

        </IonCardHeader>
      </IonCard>
  }

  return (
    <React.Fragment>

      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/snowleader" />
            </IonButtons>
            <IonTitle className="ion-text-center">
              {selectedStation ? selectedStation.name : 'No Station Data'}
            </IonTitle>

          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid fixed>
            <IonRow>
              <IonCol
                size-sm="8"
                offset-sm="2"
                size-md="6"
                offset-md="3"
                className="ion-no-padding"
              >
                {content}

                <TwoButtonSegment
                  selectedValue={sliderView}
                  onSelectValue={segmentChangeHandler}>
                </TwoButtonSegment>
                <IonSlides onIonSlideDidChange={slideChangeHandler} options={slideOpts} onIonSlidesDidLoad={init}>
                  <IonSlide>

                  <IonGrid fixed>

                  {/*Set Up Table Headers*/}
                  <IonRow>
                  <IonCol size="1">
                    Hr
                  </IonCol>
                    <IonCol size="4">
                      Snow
                    </IonCol>
                    <IonCol size="2">
                      Air Temp
                    </IonCol>
                    <IonCol size="3">
                      Wind
                    </IonCol>
                  </IonRow>
                  <IonRow>
                  <IonCol size="1">

                  </IonCol>
                    <IonCol size="2">
                      HS (cm)
                    </IonCol>
                    <IonCol size="2">
                      New (cm)
                    </IonCol>
                    <IonCol size="2">
                     Average &deg;C
                    </IonCol>
                    <IonCol size="2">
                     Spd/Gst (km/hr)
                    </IonCol>
                    <IonCol size="1">
                     Dir
                    </IonCol>
                    <IonCol size="1">
                     RH %
                    </IonCol>
                    <IonCol size="1">
                     Pr mb
                    </IonCol>
                    <IonRow className="ion-text-center">
                      {selectedStation?.data.length > 0 && (new Intl.DateTimeFormat("en-CA", {
                      month: "long",
                      day: "2-digit",
                      year:"numeric"

                    }).format(new Date(selectedStation?.data[1].measurementDateTime)))}
                    </IonRow>
                  </IonRow>
                    {selectedStation?.data.length > 0 && (
                      selectedStation?.data.map((stationData: any) => (
                        <IonRow key={stationData.stationId}>
                          <IonCol size="1">
                            {new Intl.DateTimeFormat("en-CA", {

                              hour: "numeric"
                            }).format(new Date(stationData.measurementDateTime))}
                          </IonCol>

                          {/*Render Snow Height Column*/}
                          {stationData.snowHeight ?
                            <IonCol size="2">
                              {stationData.snowHeight}
                            </IonCol>
                             :  (  <IonCol size="2"> </IonCol>)}


                             {/*Render Hourly Precip Column*/}
                             {stationData.hourlyPrecip ?
                               <IonCol size="2">
                                 {stationData.hourlyPrecip}
                               </IonCol>
                                :  (  <IonCol size="2"> </IonCol>)}


                                {/*Render Air Temp Column*/}
                                {stationData.airTempAvg ?
                                  <IonCol size="2">
                                    {stationData.airTempAvg}
                                  </IonCol>
                                   :  (  <IonCol size="2"> </IonCol>)}


                          {/*Render Wind Speed and Gust Column*/}
                          {stationData.windSpeedAvg ?
                            <IonCol size="2">
                              {stationData.windSpeedAvg + (stationData.windSpeedGust ? "G" +stationData.windSpeedGust : "")}


                            </IonCol>
                             : (  <IonCol size="2"></IonCol>)}

                             {/*Render Wind Direction Column*/}
                             {stationData.windDirCompass ?
                               <IonCol>
                                 {stationData.windDirCompass}
                               </IonCol>
                                :  (  <IonCol> </IonCol>)}


                               {/*Render Relative Humidity Column*/}
                               {stationData.relativeHumidity ?
                                 <IonCol>
                                   {stationData.relativeHumidity}
                                 </IonCol>
                                  :  (  <IonCol> </IonCol>)}

                                  {/*Render Station Pressure Column*/}
                                  {stationData.stationPressure ?
                                    <IonCol>
                                      {stationData.stationPressure}
                                    </IonCol>
                                     :  (  <IonCol> </IonCol>)}

                        </IonRow>

                      ))

                    )}

                    </IonGrid>
                  </IonSlide>
                  <IonSlide>

                    <IonGrid>
                      <IonRow>
                        <IonCol
                          size-sm="8"
                          offset-sm="2"
                          size-md="6"
                          offset-md="3"
                          className="ion-no-padding"
                        >
                          {wind.length > 0 ? renderLineChart : (
                            <div></div>)}
                          {temps.length > 0 ? renderLineChart3 : (
                            <div></div>)}
                          {snowPack.length > 0 ? renderLineChart2 : (
                            <div></div>)}
                          {precip.length > 0 ? renderBarChart : (
                            <div></div>
                          )}
                        </IonCol>
                      </IonRow>
                    </IonGrid>

                  </IonSlide>
                </IonSlides>


              </IonCol>
            </IonRow>
          </IonGrid>



        </IonContent>
      </IonPage>
    </React.Fragment>
  );
};

export default React.memo(StationDetail);
