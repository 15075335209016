import React from 'react';
import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';

const InputControl: React.FC<{
  selectedValue: '0' | '1' | '2';
  onSelectValue: (value: '0' | '1' | '2') => void;
}> = props => {

  const inputChangeHandler = (event: CustomEvent) => {
    props.onSelectValue(event.detail.value);
  };

  return (
    <IonSegment value={props.selectedValue} onIonChange={inputChangeHandler}>
    <IonSegmentButton value='0'>
      <IonLabel>HS</IonLabel>
    </IonSegmentButton>
    <IonSegmentButton value='1'>
      <IonLabel>Wind</IonLabel>
    </IonSegmentButton>
    <IonSegmentButton value='2'>
      <IonLabel>12hr Precip</IonLabel>
    </IonSegmentButton>
    </IonSegment>
  );
};

export default InputControl;
