import React, { useContext } from 'react';
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage
} from '@ionic/react';


import StationsContext from '../data/stations-context';
import GoogleMapReact from 'google-map-react';
import MapMarker from '../components/MapMarker';


const StationMap: React.FC = () => {

  console.log("Maps Rendering");


  const stationsCtx = useContext(StationsContext);


  let defualtProps:any = [];
   defualtProps = {
    center: {
      lat: 51.025,
      lng: -119.661
    },
    zoom: 6
  };

  const getMapOptions = () => {
    return {
      disableDefaultUI: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
           mapTypeIds: ['satellite', 'terrain']
         }    };
  };


  return (


      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle className="ion-text-center">Station Map</IonTitle>

          </IonToolbar>
        </IonHeader>
        <IonContent>

        <GoogleMapReact
                  bootstrapURLKeys={{ key: "AIzaSyCI2_2t5f_heTLFIXCayO_e33OOnTggRwA" }}
                  defaultCenter={defualtProps.center}
                  defaultZoom={defualtProps.zoom}
                  options={getMapOptions}
                >
                {
                  stationsCtx.stations.map(station => (

                  <MapMarker key={station.stationId}
                  lat={station.latitude}
                  lng={station.longitude}
                  name="My Marker"
                  color="blue"
                  stationId={station.stationId}

                  />
                ))}

           </GoogleMapReact>

        </IonContent>
      </IonPage>

  );
};

export default React.memo(StationMap);
