import React from 'react';

export interface StationData {
  id: string;
  airTempAvg?: string;
  measurementDateTime?: Date;
  hourlyPrecip?: string;
  snowHeight?: string;
  airTempMax?: string;
  airTempMin?: string;
  relativeHumidity?: string;
  windDirCompass?: string;
  windSpeedAvg?: string;
  windDirAvg?: string;
  windSpeedGust?: string;
  windDirGust?: string;

}

export interface Station {
  stationId: string;
  name: string;
  elevation: string;
  data: any;
  owner: string;
  latitude: string;
  longitude: string;
  utcOffset: string;
  source: string;

}

interface Context {
  stations: Station[];
  initContext: (stations: Station[]) => void;
  }

const StationsContext = React.createContext<Context>({
  stations: [],
  initContext: () => {}
});

export default StationsContext;
