import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonGrid,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonToolbar,
  IonText
} from "@ionic/react";
import "./About.css";

const About: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
        <IonTitle>About SnowLeader</IonTitle>

      </IonToolbar>
        </IonHeader>
        <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol
              size-sm="8"
              offset-sm="2"
              size-md="6"
              offset-md="3"
              className="ion-no-padding"
            >
              <IonCard>
                <IonCardContent>
<IonText>
 <h1>SnowLeader Version 1.0</h1>
 <p>Author: Andrew M. Bruce, P.Eng</p>
 <br></br>
 <p>This app retrieves data from www.avalanche.ca remote
 weather station data. Sources are Parks Canada, BC MOT, and Avalanche
 Canada.</p>
 <br></br>
 <p>Known Issue: If you see no data on the page, navigate to the 'Leaderboard'
 tab to refresh the data</p>
 <br></br>
 <h2>Leaderboard Tab</h2>
 <p>Tap/Click on the station in the list to view detailed station data</p>

 <br></br>
 <h3>HS</h3>
 <p>The HS page lists the weather stations that are reporting snow heights
 from highest to lowest</p>
<br></br>
 <h3>Wind</h3>
 <p>The wind page lists the weather stations that are reporting wind speeds
 from highest to lowest</p>
 <br></br>
  <h3>12hr Precip</h3>
  <p>The 12hr Precip pagee lists the weather stations that are reporting precip
  and adds up all the precip from the last 12 hours and lists the stations from
  highest to lowest</p>
<br></br>
<h1>Future Development</h1>
  <h2>SnowLeader Version 2.0</h2>

<ul>
  <li>Ski resort weather observation data to be added</li>
  <li>Save favorite stations</li>
  <li>Search stations</li>
  <li>Generate reports for morning/evening weather meetings</li>
</ul>
  <br></br>
  <h1>Feedback and Suggestions</h1>
  <p>Please send feedback and suggestions to Andrew Bruce at a.bruce@unb.ca</p>
</IonText>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(About);
