import React, { useState,  useCallback } from "react";

import StationsContext, { Station } from "./stations-context";



const StationsContextProvider: React.FC = props => {
  const [stations, setStations] = useState<Station[]>([]);


  const initContext = useCallback( (stations) => {
    setStations(stations);
//setTimeout(function(){ alert("Hello"); }, 3000);
console.log("init context");
  }, []);

  return (
    <StationsContext.Provider value={{
      stations,initContext
   }}>
      {props.children}
    </StationsContext.Provider>
  );
};

export default StationsContextProvider;
