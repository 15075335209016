import React from 'react';

import '../theme/mapmarker.css';
import {IonButton} from '@ionic/react';


const MapMarker:React.FC<{
  color: string;
  name: string;
  lat:string;
  lng:string;
  stationId:string
}> = props => {
    return (
<div>


  <IonButton
    fill="clear"
    routerLink={`/station/${props.stationId}`}
  >
  <div className="marker"
    style={{ backgroundColor: props.color}}
    title={props.name}
  />
  </IonButton>



  </div>
    );
  };

  export default MapMarker;
