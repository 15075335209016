import React,  {useState, useEffect, useContext} from 'react';
import { IonContent, IonHeader,IonLoading,
  IonPage, IonTitle, IonToolbar,IonSlides,
  IonSlide, IonList, IonItem, IonLabel,
  IonCardContent, IonCard, IonGrid,IonRow, IonCol,IonSkeletonText
} from '@ionic/react';
import './LeaderBoard.css';
import InputControl from '../components/InputControl';
import axios from "axios";
import StationsContext from '../data/stations-context';


const LeaderBoard: React.FC = () => {


  const [sliderView, setSliderView] = useState< '0' | '1' | '2'>('0');
  const [latestData, setLatestData] = useState<any>([]);
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [topHS, setTopHS] = useState<any[]>([]);
  const [twelveHourPrecip, setTwelveHourPrecip] = useState<any>([]);
  const [wind, setWind] = useState<any>([]);

const stationsCtx = useContext(StationsContext);

  const segmentChangeHandler = (selectedValue: '0' | '1' | '2') => {
    setSliderView(selectedValue);
  if(swiper.slideTo){
    swiper.slideTo(selectedValue,400);
  }

  };

  const slideChangeHandler = () => {

  console.log("slider change");
    setSliderView(swiper.activeIndex);

  }

  const slideOpts = {
    speed: 400
  };

  const [swiper, setSwiper] = useState<any>({});

  const init = async function(this: any) {
      setSwiper(await this.getSwiper());
  };



useEffect(()=>{

  let promises: any = [];
  let loadedStations: any = [];

   axios.get("https://api.avalanche.ca/weather/stations/?format=json",)
   .then((stations : any) =>{


     for (let key = 0; key < stations.data.length; key++){
    //   let timezone = "America/Vancouver";
      // if(stations.data[key].utcOffset == "-7"){
      //   timezone =
      // }
           loadedStations.push({
             stationId: stations.data[key].stationId,
             name: stations.data[key].name,
             elevation: stations.data[key].elevation,
             latitude: stations.data[key].latitude,
             longitude: stations.data[key].longitude,
             data: "",
             owner: stations.data[key].owner,
             utcOffset: stations.data[key].utcOffset,
             source: stations.data[key].source
           });
       promises.push(axios.get(
           "https://api.avalanche.ca/weather/stations/" +stations.data[key].stationId +
             "/measurements/?format=json"
         ));



   }//end for

     axios.all(promises).then(function(results) {
    results.forEach(function(response:any) {

      if(response.data.length > 0){

        let obj = loadedStations.findIndex((o:any) => o.stationId === response.data[0].station);
         loadedStations[obj].data = response.data;
      }



    })

    setLatestData(loadedStations);
    stationsCtx.initContext(loadedStations);
    setShowLoading(false);

});
   });//end outer axios



},[]);

useEffect(() =>{
//console.log("latestData: " + latestData);
//const loadedStations: any = [];
const snowHeights: any = [];
const precip: any = [];
const loadedWind: any = [];

for (const key in latestData) {
  let topTwelveHourPrecip = 0;

  for (let datakey = 0; datakey < 12; datakey++) {

  if(latestData[key].data[datakey]?.hourlyPrecip){

    topTwelveHourPrecip = topTwelveHourPrecip + latestData[key].data[datakey]?.hourlyPrecip;

  }

}//data loop


  precip.push({
          stationId: latestData[key].stationId,
          name: latestData[key].name,
          topTwelveHourPrecip: topTwelveHourPrecip
        });

if(latestData[key].data[0]?.windSpeedAvg){
        loadedWind.push({
                stationId: latestData[key].stationId,
                name: latestData[key].name,
                wind: latestData[key].data[0].windSpeedAvg
              });
}

if(latestData[key].data[0]?.snowHeight){

  snowHeights.push({
          stationId: latestData[key].stationId,
          name: latestData[key].name,
          snowHeight: latestData[key].data[0].snowHeight,
          elevation: latestData[key].elevation
        });
}

}

setTopHS(snowHeights.sort((a:any, b:any) => {return b.snowHeight - a.snowHeight}));
setTwelveHourPrecip(precip.sort((a:any, b:any) => {return b.topTwelveHourPrecip - a.topTwelveHourPrecip}));
setWind(loadedWind.sort((a:any, b:any) => {return b.wind - a.wind}));

},[latestData]);



  return (
    <IonPage>
      <IonHeader>
      <IonToolbar>
        <IonTitle className="ion-text-center">SnowLeader</IonTitle>

      </IonToolbar>
        <IonToolbar>
        <InputControl
          selectedValue={sliderView}
          onSelectValue={segmentChangeHandler}
        />
          </IonToolbar>
      </IonHeader>
      <IonContent>


        <IonLoading
          isOpen={showLoading}
          message={'Loading 114 Remote Weather Station Observations and Calculating Snow Leaders'}/>

          <IonGrid>
            <IonRow>
              <IonCol
                size-sm="8"
                offset-sm="2"
                size-md="6"
                offset-md="3"
                className="ion-no-padding"
              >
          <IonCard>
          <IonCardContent>

        <IonSlides onIonSlideDidChange={slideChangeHandler} options={slideOpts} onIonSlidesDidLoad={init}>
         <IonSlide>

         {topHS.length === 0 && (
           <IonSkeletonText animated style={{ width: '60%' }} />
         )}
         {topHS.length > 0 && (
           <IonList>
             {topHS.map((hs : any) => (
               <IonItem  routerLink={`/station/${hs.stationId}`} key={hs.stationId}>
                 <IonLabel>
                   <h2>{hs.name}</h2>
                    <h1>{hs.snowHeight}cm</h1>
                 </IonLabel>


               </IonItem>

             ))}
           </IonList>
         )}
         </IonSlide>
         <IonSlide>
         {wind.length === 0 && (
           <h2 className="ion-text-center">Data Error</h2>
         )}
         {wind.length > 0 && (
           <IonList>
             {wind.map((w:any) => (
               <IonItem routerLink={`/station/${w.stationId}`} key={w.stationId} button>
                 <IonLabel>
                   <h2>{w.name}</h2>
                   <h1>{w.wind} km/hr</h1>
                 </IonLabel>
               </IonItem>
             ))}
           </IonList>
         )}
         </IonSlide>
         <IonSlide>
         {twelveHourPrecip.length === 0 && (
           <h2 className="ion-text-center">Data Error</h2>
         )}
         {twelveHourPrecip.length > 0 &&(
           <IonList>
             {twelveHourPrecip.map((s:any) => (
               <IonItem routerLink={`/station/${s.stationId}`} key={s.stationId}>
                 <IonLabel>
                   <h2>{s.name}</h2>
                   <h1>{s.topTwelveHourPrecip.toFixed(2)}mm</h1>
                 </IonLabel>
               </IonItem>
             ))}
           </IonList>
         )}
         </IonSlide>
       </IonSlides>
       </IonCardContent>
       </IonCard>
       </IonCol>
     </IonRow>
     </IonGrid>
      </IonContent>
    </IonPage>
  );
};


export default React.memo(LeaderBoard);
